<template>
  <div
    class="flex flex-col gap-2 m-0 pl-0 mt-8 desktop:mt-0 desktop:ml-8 desktop:pl-8 desktop:!border-l desktop:!border-etGray-primary"
  >
    <template
      v-if="
        content == 'upgradeSub' &&
        subscription[1] == 'annually' &&
        account.attributes.subscription.attributes.plan_identifier ===
          'freemium' &&
        !couponDiscount
      "
    >
      <div class="flex gap-2 items-center mb-2">
        <font-awesome-icon
          icon="fa-solid fa-circle-check"
          class="text-etGreen-100"
        />

        <TextTiny>
          {{ $t('customer.upgrade_sub.trial.first_bullet') }}
        </TextTiny>
      </div>
      <div class="flex gap-2 items-center mb-2">
        <font-awesome-icon
          icon="fa-solid fa-circle-check"
          class="text-etGreen-100"
        />
        <TextTiny>
          {{
            $t('customer.upgrade_sub.trial.second_bullet', { date: chargeDate })
          }}
        </TextTiny>
      </div>
    </template>
    <TextSmall :bold="true">
      {{ $t('customer.voucher.overview') }}
    </TextSmall>
    <div
      v-if="
        content == 'upgradeSub' &&
        subscription[1] == 'annually' &&
        account.attributes.subscription.attributes.plan_identifier ===
          'freemium' &&
        !couponDiscount
      "
      class="bg-etBlue-10 h-full p-4"
    >
      <div
        v-if="Number(billingPortalBalance) > 0"
        class="flex justify-between items-center mb-1"
      >
        <TextTiny>{{ $t('customer.history.type.wallet') }}</TextTiny>
        <TextSmall>- {{ billingPortalBalance }} €</TextSmall>
      </div>
      <div
        v-if="
          content == 'upgradeSub' &&
          subscription[1] == 'annually' &&
          account.attributes.subscription.attributes.plan_identifier ===
            'freemium' &&
          !couponDiscount
        "
        class="flex justify-between items-center mb-1"
      >
        <TextTiny success>
          {{ $t('customer.upgrade_sub.payment.free_trial') }}
        </TextTiny>
        <TextSmall success>0.00 €</TextSmall>
      </div>
      <template v-if="content == 'upgradeSub' && subscription[1] == 'annually'">
        <div class="my-6 mx-1">
          <CartInfoCard
            :item-title="subscription[0].title"
            :price="pricePerPeriod"
            :price-info="$t('shared.label.billed_annually')"
            :tag-text="
              account.attributes.subscription.attributes.plan_identifier ===
              'freemium'
                ? $t('customer.upgrade_sub.payment.free_trial')
                : ''
            "
            :extra-info-text="
              account.attributes.subscription.attributes.plan_identifier ===
              'freemium'
                ? $t('customer.upgrade_sub.payment.cancel_anytime')
                : ''
            "
          />
        </div>
      </template>
      <div
        class="flex justify-between items-center mb-1 mt-4 pt-4 border-t border-etBlue-10"
      >
        <TextTiny :bold="true">
          {{ $t('customer.voucher.total_tax') }}
        </TextTiny>
        <TextSmall :bold="true">
          {{ 0 }}
          €
        </TextSmall>
      </div>
      <div v-if="enableVat" class="flex justify-between items-center mb-1">
        <TextTiny>{{ $t('customer.voucher.vat') }}</TextTiny>
        <TextSmall>{{ 0 }} €</TextSmall>
      </div>
      <div class="flex justify-between items-center mb-1">
        <TextSmall success :bold="true">
          {{ $t('customer.voucher.total') }}
        </TextSmall>
        <TextRegular class="text-etGreen-100" :bold="true">
          {{ 0 }} €
        </TextRegular>
      </div>

      <div v-if="enableVat" class="flex justify-between items-center mb-1">
        <TextTiny class="flex"
          >{{ $t('shared.label.freemium.price_total_after') }}
          <div class="textItalic">({{ subPeriod }})</div>
        </TextTiny>

        <TextTiny>
          {{ totalPriceWithVatForFreemiums }}
          €({{ $t('shared.label.freemium.price_with_vat') }})
        </TextTiny>
      </div>

      <div v-else class="flex justify-between items-center mb-1">
        <TextTiny class="flex"
          >{{ $t('shared.label.freemium.price_total_after') }}
          <div class="textItalic">({{ subPeriod }})</div>
        </TextTiny>

        <TextTiny>
          {{ price }} €({{ $t('shared.label.freemium.price_without_vat') }})
        </TextTiny>
      </div>
    </div>
    <div v-else class="bg-etBlue-10 h-full p-4">
      <div class="flex justify-between items-center mb-1">
        <TextTiny>{{ priceTitle }}</TextTiny>
        <TextSmall>
          {{ price }}
          €
        </TextSmall>
      </div>
      <div
        v-if="Number(billingPortalBalance) > 0"
        class="flex justify-between items-center mb-1"
      >
        <TextTiny>{{ $t('customer.history.type.wallet') }}</TextTiny>
        <TextSmall>- {{ billingPortalBalance }} €</TextSmall>
      </div>
      <div
        v-if="
          content == 'upgradeSub' &&
          subscription[1] == 'annually' &&
          account.attributes.subscription.attributes.plan_identifier ===
            'freemium' &&
          !couponDiscount
        "
        class="flex justify-between items-center mb-1"
      >
        <TextTiny success>
          {{ $t('customer.upgrade_sub.payment.free_trial') }}
        </TextTiny>
        <TextSmall success>0.00 €</TextSmall>
      </div>
      <div
        v-if="content == 'upgradeSub'"
        class="flex justify-between items-center mb-1"
      >
        <TextTiny class="italic">
          {{ subPeriod }}
        </TextTiny>
      </div>
      <template v-if="content == 'upgradeSub' && subscription[1] == 'annually'">
        <div class="my-6 mx-1">
          <CartInfoCard
            :item-title="subscription[0].title"
            :price="pricePerPeriod"
            :price-info="$t('shared.label.billed_annually')"
            :tag-text="
              account.attributes.subscription.attributes.plan_identifier ===
                'freemium' && !couponDiscount
                ? $t('customer.upgrade_sub.payment.free_trial')
                : ''
            "
            :extra-info-text="
              account.attributes.subscription.attributes.plan_identifier ===
                'freemium' && !couponDiscount
                ? $t('customer.upgrade_sub.payment.cancel_anytime')
                : ''
            "
          />
        </div>
      </template>
      <div
        class="flex justify-between items-center mb-1 mt-4 pt-4 border-t border-etBlue-10"
      >
        <TextTiny :bold="true">
          {{ $t('customer.voucher.total_tax') }}
        </TextTiny>
        <TextSmall :bold="true">
          {{ getTotalExTax() }}
          €
        </TextSmall>
      </div>
      <div v-if="enableVat" class="flex justify-between items-center mb-1">
        <TextTiny>{{ $t('customer.voucher.vat') }}</TextTiny>
        <TextSmall>{{ getVat() }} €</TextSmall>
      </div>
      <div class="flex justify-between items-center mb-1">
        <TextSmall :bold="true">
          {{ $t('customer.voucher.total') }}
        </TextSmall>
        <TextRegular :bold="true"> {{ getTotal() }} € </TextRegular>
      </div>
    </div>
  </div>
</template>
<script setup>
import CartInfoCard from '@/components/cards/CartInfoCard'
import TextRegular from '@/components/shared/font/text/TextRegular'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TextTiny from '@/components/shared/font/text/TextTiny'
import { chargeVAT } from '@/helpers/billing'
import i18n from '@/i18n'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const props = defineProps({
  content: String,
  subscription: {
    type: Object,
    default: null
  }
})

const totalExTax = ref(null)
const vat25 = ref(null)
const total = ref(null)

const enableVat = computed(() => {
  return chargeVAT(billing.value.attributes.accounting_code)
})

const totalPriceWithVatForFreemiums = computed(() => {
  const total = Number(price.value) + Number(getVat())
  return couponDiscount.value === 100 ? 0 : total
})

const billingPortalBalance = computed(
  () => store.state.payment.billingPortalBalance
)
const billing = computed(() => store.state.payment.billing)
const currentProject = computed(() => store.state.project.currentProject)
const account = computed(() => store.state.account.account)
const couponDiscount = computed(() => store.state.subscriptions.couponDiscount)

const priceTitle = computed(() => {
  if (props.content == 'upgradeSub') {
    let period = $t('customer.upgrade_sub.12months')
    if (props.subscription[1] == 'monthly') {
      period = $t('customer.upgrade_sub.1month')
    }
    return `${props.subscription[0].title} - ${period}`
  }
  return $t('customer.voucher.project_price')
})

const pricePerPeriod = computed(() => {
  if (props.subscription[1] == 'annually') {
    return props.subscription.title != 'Enterprise'
      ? Number(props.subscription[0].annually) * 12 +
          '€/' +
          $t('shared.label.year')
      : props.subscription.annually
  }
  return props.subscription.title != 'Enterprise'
    ? Number(props.subscription[0].monthly) +
        '€/' +
        $t('customer.upgrade_sub.month')
    : props.subscription.monthly
})

const price = computed(() => {
  if (props.content == 'upgradeSub') {
    let price = Number(props.subscription[0].annually) * 12
    if (props.subscription[1] == 'monthly') {
      price = Number(props.subscription[0].monthly)
    }
    totalExTax.value = couponDiscount.value === 100 ? 0 : price
    return couponDiscount.value === 100 ? 0 : price.toFixed(2)
  }
  return formatPrice(currentProject.value.attributes.price.amount)
})

const chargeDate = computed(() => {
  const chargeDay = new Date()
  chargeDay.setDate(chargeDay.getDate() + 7)
  return `${chargeDay.getDate()}-${
    chargeDay.getMonth() + 1
  }-${chargeDay.getFullYear()}`
})

const subPeriod = computed(() => {
  const current = new Date()
  const currentTo = new Date()

  const fromDate = `${current.getDate()}-${
    current.getMonth() + 1
  }-${current.getFullYear()}`
  if (
    props.subscription[1] == 'annually' &&
    account.value.attributes.subscription.attributes.plan_identifier ===
      'freemium'
  ) {
    currentTo.setDate(current.getDate() + 7)
  }
  let toDate = `${currentTo.getDate()}-${currentTo.getMonth() + 1}-${
    currentTo.getFullYear() + 1
  }`
  if (props.subscription[1] == 'monthly') {
    toDate = `${current.getDate()}-${
      current.getMonth() + 2
    }-${current.getFullYear()}`
  }
  return $t('customer.upgrade_sub.period', {
    fromDate: `${fromDate}`,
    toDate: `${toDate}`
  })
})

const getBillingPortal = (payload) =>
  store.dispatch('payment/getBillingPortal', payload)

onMounted(async () => {
  getBillingPortal()
})

function formatPrice(price) {
  if (price === null) return '-'
  return price / 100
}
function getTotalExTax() {
  if (props.content == 'upgradeSub') {
    const total = totalExTax.value - Number(billingPortalBalance.value)
    return couponDiscount.value === 100 ? 0 : total.toFixed(2)
  }
  const price = currentProject.value.attributes.price.amount
  totalExTax.value =
    (price / 100).toFixed(2) - Number(billingPortalBalance.value)
  return totalExTax.value > 0 ? Number(totalExTax.value) : 0
}
function getVat() {
  vat25.value = (totalExTax.value * 25) / 100
  return vat25.value > 0 && enableVat.value ? vat25.value.toFixed(2) : 0
}
function getTotal() {
  total.value =
    Number(totalExTax.value) +
    Number(vat25.value) -
    Number(billingPortalBalance.value)
  return total.value > 0 ? total.value.toFixed(2) : 0
}
</script>
